<template>
  <v-card
    class="flex-1 mb-3 flex flex-row"
    v-for="item in items"
    :key="item.id"
  >
    <img src="" alt="" class="flex-1" />
    <div class="flex-2">
      <h4 class="font-bold text-xl">{{ item.product.name }}</h4>
      <h6 class="text-gray-400 text-md">
        Cantitate {{ item.quantity }}, Total {{ formatPrice(item.total) }}
      </h6>
    </div>
    <div class="flex-1 text-right">
      <button @click.prevent="$emit('removed', item)">X</button>
    </div>
  </v-card>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VCard from "./VCard.vue";
export default {
  components: { VCard },
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },
  },
};
</script>

<style></style>
