<template>
  <div class="mb-2">
    <v-label :for="inputName" v-text="labelName"></v-label>
    <v-input
      :type="inputType"
      :name="inputName"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="w-full"
    ></v-input>
    <span
      class="inline-block mt-2 text-red-500 text-sm"
      v-if="error !== ''"
      v-text="error"
    />
  </div>
</template>

<script>
import VInput from "./ui/VInput.vue";
import VLabel from "./ui/VLabel.vue";
export default {
  props: {
    labelName: String,
    inputName: String,
    inputType: String,
    error: String,
    modelValue: [String, Boolean, Number],
  },
  components: { VLabel, VInput },
  emits: ["update:modelValue"],
};
</script>

<style></style>
