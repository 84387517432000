<template>
  <h4 class="mb-3 text-lg capitalize text-black font-medium">
    <slot></slot>
  </h4>
</template>

<script>
export default {};
</script>

<style></style>
