<template>
  <button
    class="
      bg-yellow-600
      text-white
      font-medium
      py-2
      px-4
      rounded
      hover:bg-yellow-800
      active:bg-green-700
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style></style>
