<template>
  <auth-layout>
    <v-container>
      <!-- <checkout-steps :step="3"></checkout-steps> -->
      <page-section>
        <page-header>
          <page-title>Sumar comanda</page-title>
        </page-header>

        <div class="flex flex-col md:flex-row md:space-x-3">
          <div class="md:w-2/3 order-2 md:order-2">
            <form
              action=""
              method="POST"
              @submit.prevent="onSubmit"
              @keydown="form.errors.clear($event.target.name)"
            >
              <v-card class="mb-3">
                <v-card-title>
                  <h2 class="font-bold text-xl">Detalii facturare</h2>
                </v-card-title>
                <v-label>Persoana fizica/juridica</v-label>
                <select-customer-type
                  selectName="customer_type_id"
                  v-model="form.customer_type_id"
                  class="w-full mb-4"
                />
                <v-error
                  :error="
                    form.errors.has('customer_type_id')
                      ? form.errors.get('customer_type_id')
                      : ''
                  "
                />
                <div class="flex space-x-2">
                  <form-control
                    label-name="Prenume"
                    input-name="billing_address.first_name"
                    input-type="text"
                    v-model="form.billing_address.first_name"
                    :error="
                      form.errors.has('billing_address.first_name')
                        ? form.errors.get('billing_address.first_name')
                        : ''
                    "
                    class="flex-1"
                  />
                  <form-control
                    label-name="Nume"
                    input-name="billing_address.last_name"
                    input-type="text"
                    v-model="form.billing_address.last_name"
                    :error="
                      form.errors.has('billing_address.last_name')
                        ? form.errors.get('billing_address.last_name')
                        : ''
                    "
                    class="flex-1"
                  />
                </div>

                <template v-if="isCompany">
                  <form-control
                    label-name="Companie"
                    input-name="company"
                    input-type="text"
                    v-model="form.company"
                    :error="
                      form.errors.has('company')
                        ? form.errors.get('company')
                        : ''
                    "
                    class="flex-1"
                  ></form-control>
                  <div class="flex space-x-2">
                    <form-control
                      label-name="Cod fiscal"
                      input-name="fiscal_code"
                      input-type="text"
                      v-model="form.fiscal_code"
                      :error="
                        form.errors.has('fiscal_code')
                          ? form.errors.get('fiscal_code')
                          : ''
                      "
                      class="flex-1"
                    />
                    <form-control
                      label-name="Numar inregistrare"
                      input-name="registration_number"
                      input-type="text"
                      v-model="form.registration_number"
                      :error="
                        form.errors.has('registration_number')
                          ? form.errors.get('registration_number')
                          : ''
                      "
                      class="flex-1"
                    ></form-control>
                  </div>
                </template>
                <div class="flex space-x-2">
                  <form-control
                    label-name="Email"
                    input-name="billing_address.email"
                    input-type="text"
                    v-model="form.billing_address.email"
                    :error="
                      form.errors.has('billing_address.email')
                        ? form.errors.get('billing_address.email')
                        : ''
                    "
                    class="flex-1"
                  />
                  <form-control
                    label-name="Telefon"
                    input-name="billing_address.phone"
                    input-type="text"
                    v-model="form.billing_address.phone"
                    :error="
                      form.errors.has('billing_address.phone')
                        ? form.errors.get('billing_address.phone')
                        : ''
                    "
                    class="flex-1"
                  />
                </div>
                <form-control
                  label-name="Adresa"
                  input-name="billing_address.street"
                  input-type="text"
                  v-model="form.billing_address.street"
                  :error="
                    form.errors.has('billing_address.street')
                      ? form.errors.get('billing_address.street')
                      : ''
                  "
                ></form-control>
                <div class="flex space-x-2">
                  <form-control
                    label-name="Cod Postal"
                    input-name="billing_address.postal_code"
                    input-type="text"
                    v-model="form.billing_address.postal_code"
                    :error="
                      form.errors.has('billing_address.postal_code')
                        ? form.errors.get('billing_address.postal_code')
                        : ''
                    "
                  ></form-control>

                  <form-control
                    label-name="Oras"
                    input-name="billing_address.locality"
                    input-type="text"
                    v-model="form.billing_address.locality"
                    :error="
                      form.errors.has('billing_address.locality')
                        ? form.errors.get('billing_address.locality')
                        : ''
                    "
                  ></form-control>
                  <form-control
                    label-name="Judet"
                    input-name="billing_address.region"
                    input-type="text"
                    v-model="form.billing_address.region"
                    :error="
                      form.errors.has('billing_address.region')
                        ? form.errors.get('billing_address.region')
                        : ''
                    "
                  ></form-control>
                  <div>
                    <v-label for="billing_address.country_id"> Tara</v-label>
                    <select-country
                      selectName="billing_address.country_id"
                      v-model="form.billing_address.country_id"
                      class="w-full"
                    />
                  </div>
                </div>
              </v-card>
              <v-card class="mb-3">
                <v-card-title>
                  <h2 class="font-bold text-xl">Adresa livrare</h2>
                </v-card-title>
                <div class="flex items-center">
                  <v-label>La fel ca la facturare</v-label>
                  <v-checkbox
                    v-model="form.shipping_address.same_as_billing"
                    class="ml-2"
                  />
                </div>
                <v-error
                  :error="
                    form.errors.has('shipping_address.same_as_billing')
                      ? form.errors.get('shipping_address.same_as_billing')
                      : ''
                  "
                />
                <div v-if="!form.shipping_address.same_as_billing">
                  <form-control
                    label-name="Email"
                    input-name="shipping_address.email"
                    input-type="text"
                    v-model="form.shipping_address.email"
                    :error="
                      form.errors.has('shipping_address.email')
                        ? form.errors.get('shipping_address.email')
                        : ''
                    "
                    class="flex-1"
                  ></form-control>
                  <form-control
                    label-name="Prenume"
                    input-name="shipping_address.first_name"
                    input-type="text"
                    v-model="form.shipping_address.first_name"
                    :error="
                      form.errors.has('shipping_address.first_name')
                        ? form.errors.get('shipping_address.first_name')
                        : ''
                    "
                    class="flex-1"
                  ></form-control>
                  <form-control
                    label-name="Nume"
                    input-name="shipping_address.last_name"
                    input-type="text"
                    v-model="form.shipping_address.last_name"
                    :error="
                      form.errors.has('shipping_address.last_name')
                        ? form.errors.get('shipping_address.last_name')
                        : ''
                    "
                    class="flex-1"
                  ></form-control>
                  <div class="flex space-x-2">
                    <form-control
                      label-name="Telefon"
                      input-name="shipping_address.phone"
                      input-type="text"
                      v-model="form.shipping_address.phone"
                      :error="
                        form.errors.has('shipping_address.phone')
                          ? form.errors.get('shipping_address.phone')
                          : ''
                      "
                      class="flex-1"
                    ></form-control>
                  </div>
                  <form-control
                    label-name="Adresa"
                    input-name="shipping_address.street"
                    input-type="text"
                    v-model="form.shipping_address.street"
                    :error="
                      form.errors.has('shipping_address.street')
                        ? form.errors.get('shipping_address.street')
                        : ''
                    "
                  ></form-control>
                  <div class="flex space-x-2">
                    <form-control
                      label-name="Cod Postal"
                      input-name="shipping_address.postal_code"
                      input-type="text"
                      v-model="form.shipping_address.postal_code"
                      :error="
                        form.errors.has('shipping_address.postal_code')
                          ? form.errors.get('shipping_address.postal_code')
                          : ''
                      "
                    ></form-control>

                    <form-control
                      label-name="Oras"
                      input-name="shipping_address.locality"
                      input-type="text"
                      v-model="form.shipping_address.locality"
                      :error="
                        form.errors.has('shipping_address.locality')
                          ? form.errors.get('shipping_address.locality')
                          : ''
                      "
                    ></form-control>
                    <form-control
                      label-name="Judet"
                      input-name="shipping_address.region"
                      input-type="text"
                      v-model="form.shipping_address.region"
                      :error="
                        form.errors.has('shipping_address.region')
                          ? form.errors.get('shipping_address.region')
                          : ''
                      "
                    ></form-control>
                    <div>
                      <v-label for="shipping_address.country_id"> Tara</v-label>
                      <select-country
                        selectName="shipping_address.country_id"
                        v-model="form.shipping_address.country_id"
                        class="w-full"
                      />
                    </div>
                  </div>
                </div>
              </v-card>
              <v-card class="mb-3">
                <v-card-title>
                  <h2 class="font-bold text-xl">Metoda de plata</h2>
                </v-card-title>
                <select-payment-method
                  selectName="payment_method_id"
                  v-model="form.payment_method_id"
                  class="w-full"
                />
              </v-card>
              <v-card class="mb-3">
                <v-card-title>
                  <h2 class="font-bold text-xl flex flex-row justify-between">
                    Total
                    <span> {{ formatPrice(cart.total) }} </span>
                  </h2>
                </v-card-title>
              </v-card>
              <button-primary :disabled="form.isLoading" class="block w-full">
                Confirma
              </button-primary>
            </form>
          </div>
          <div class="md:w-1/3 order-1 md:order-2">
            <checkout-summary-list :items="cart.items" />
          </div>
        </div>

        <!-- <div class="mt-10 sm:w-1/2 text-center mx-auto">
        <router-link
          class="block text-gray-400 mt-3"
          :to="{ name: 'checkout.shipping' }"
        >
          Inapoi la Pasul 2
        </router-link>
      </div> -->
      </page-section>
    </v-container>
  </auth-layout>
</template>

<script>
import { priceFormatter, submitForm } from "@/helpers";
import Form from "../core/Form";
import ButtonPrimary from "../components/ButtonPrimary.vue";
import PageHeader from "../components/PageHeader.vue";
import VCard from "../components/VCard.vue";
import VContainer from "../components/VContainer.vue";

import AuthLayout from "../layout/AuthLayout.vue";
// import CheckoutSteps from "../components/CheckoutSteps.vue";
import VCardTitle from "../components/VCardTitle.vue";
import VLabel from "../components/ui/VLabel.vue";
import FormControl from "../components/FormControl.vue";
import SelectCountry from "../components/SelectCountry.vue";
import SelectPaymentMethod from "../components/SelectPaymentMethod.vue";
import PageTitle from "../components/PageTitle.vue";
import cartService from "../services/cartService";
import checkoutService from "../services/checkoutService";
import CheckoutSummaryList from "../components/CheckoutSummaryList.vue";
import PageSection from "../components/PageSection.vue";
import VCheckbox from "../components/VCheckbox.vue";
import VError from "../components/ui/VError.vue";
import SelectCustomerType from "../components/SelectCustomerType.vue";
import authService from "../services/authService";
import authApi from "../api/resources/authApi";

export default {
  components: {
    VContainer,
    PageHeader,
    ButtonPrimary,
    VCard,
    AuthLayout,
    VCardTitle,
    FormControl,
    SelectCountry,
    SelectPaymentMethod,
    VLabel,
    PageTitle,
    CheckoutSummaryList,
    PageSection,
    VCheckbox,
    VError,
    SelectCustomerType,
  },
  data() {
    return {
      form: new Form({
        email: "",
        customer_type_id: "",
        company: "",
        fiscal_code: "",
        registration_number: "",

        billing_address: {
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          street: "",
          locality: "",
          region: "",
          postal_code: "",
          country_id: "",
        },

        shipping_address: {
          same_as_billing: true,
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          street: "",
          locality: "",
          region: "",
          postal_code: "",
          country_id: "",
        },

        payment_method_id: "",
      }),
      cart: {},
      gatewayform: {},
    };
  },
  computed: {
    isCompany() {
      return this.form.customer_type_id == 2;
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },

    // submitNetopiaForm(path, params, method = "post") {
    //   const form = document.createElement("form");
    //   form.method = method;
    //   form.action = path;

    //   for (const key in params) {
    //     // if (params.hasOwnProperty(key)) {
    //     if (Object.prototype.hasOwnProperty.call(params, key)) {
    //       const hiddenField = document.createElement("input");
    //       hiddenField.type = "hidden";
    //       hiddenField.name = key;
    //       hiddenField.value = params[key];

    //       form.appendChild(hiddenField);
    //     }
    //   }

    //   document.body.appendChild(form);

    //   form.submit();
    // },

    onSubmit() {
      this.form.submitService(checkoutService, "store").then((response) => {
        if (response.data.gateway) {
          submitForm(
            response.data.gateway.redirect_url,
            response.data.gateway.payload,
            "post"
          );
        } else {
          cartService.destroyInstance();
          this.$router.push({
            name: "checkout.thanks",
            query: { orderId: response.data.id },
          });
        }
      });
    },

    remove(item) {
      cartService
        .destroyItem(item.id)
        .then(() => this.loadCart())
        .catch((error) => console.log(error));
    },

    loadCart() {
      cartService
        .show()
        .then((response) => {
          this.cart = response.data;
          this.items = response.data.items.map((item) => {
            return {
              item_id: item.quotation_id,
              quantity: item.quantity,
            };
          });

          if (!this.cart.items.length) console.log("Redirect");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404)
            this.$router.push({ name: "carts.show" });
        });
    },
  },

  created() {
    this.loadCart();

    if (authService.check()) {
      authApi.me().then((response) => {
        const savedForm = this.form.data();

        this.form.setData({
          ...savedForm,
          billing_address: {
            ...savedForm.billing_address,
            email: response.data.email,
          },
        });
      });
    }
  },
};
</script>

<style></style>
