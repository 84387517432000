import { CHECKOUTS_THANKS, CHECKOUTS_ORDERS } from "../endpoints";
import api from "../api";

export default {
    thanks(query) {
        let url = new URL(CHECKOUTS_THANKS);

        if (query !== null) url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },
    store(cart, data) {
        const url = new URL(CHECKOUTS_ORDERS.replace(":cart", cart));

        return api.post(url, data);
    }
}


